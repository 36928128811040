
import { Component, Vue } from 'vue-property-decorator'
import {
  apiGameShopSubjectDeployManagerAdd,
  apiGameShopSubjectDeployManagerEdit,
  apiGameShopSubjectDeployManagerDetail
} from '@/api/shop'
import MaterialSelect from '@/components/material-select/index.vue'
import LsEditor from '@/components/editor.vue'
import { PageMode } from '@/utils/type'

@Component({
    components: {
        LsEditor,
        MaterialSelect
    }
})
export default class GameUIEdit extends Vue {
    /** S Data **/
    mode: string = PageMode.ADD // 当前页面: add-添加角色 edit-编辑角色

    // 表单数据
    form: any = {
        id: 0,
        name: '',
        platform_domain: '',
        mac: '',
        ip: '',
        is_platform_agent: 0,
        day_online_package_count: 0,
        shop_app_number: 0,
        shop_number: 0,
        is_show: 1,
        secret_key: '',

    }

    // 表单校验
    rules: object = {
      secret_key: [
            {
                required: true,
                message: '请输入秘钥',
                trigger: 'blur'
            }
        ],

        platform_domain: [{
        	required: true,
        	message: '请输入域名',
        	trigger: 'blur'
        }],
      shop_app_number: [{
        	required: true,
        	message: '请输入商户APP数量',
        	trigger: 'blur'
        }],
      shop_number: [{
        required: true,
        message: '请输入商户数量',
        trigger: 'blur'
      }],

    }

    /** E Data **/

    /** S Methods **/
    // 提交表单
    onSubmit(formName: string) {
        const refs = this.$refs[formName] as HTMLFormElement
        refs.validate((valid: boolean): void => {
            if (!valid) {
                return
            }

            // 请求发送
            switch (this.mode) {
                case PageMode.ADD:
                    return this.handleAdd()
                case PageMode.EDIT:
                    return this.handleEdit()
            }
        })
    }

    // 添加帮助文章
    handleAdd() {
      apiGameShopSubjectDeployManagerAdd(this.form).then(() => {
            setTimeout(() => this.$router.go(-1), 500)
        })
    }

    // 编辑帮助文章
    handleEdit() {
      apiGameShopSubjectDeployManagerEdit(this.form).then(() => {
            setTimeout(() => this.$router.go(-1), 500)
        })
    }

    // 初始化表单数据: 编辑
    initEdit() {
      apiGameShopSubjectDeployManagerDetail({
            id: this.form.id,
        }).then(res => {
            Object.keys(res).map(item => {
                this.$set(this.form, item, res[item])
            })
        })
    }

    /** E Methods **/

    /** S Life Cycle **/
    created() {
        const query: any = this.$route.query
        if (query.mode) {
            this.mode = query.mode
        }

        if (this.mode === PageMode.EDIT) {
            this.form.id = query.id * 1
            this.initEdit()
        }
    }

    /** E Life Cycle **/
}
